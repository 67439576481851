/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;


// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$bmw-fed-primary: mat-palette($mat-indigo);
//$bmw-fed-accent: mat-palette($mat-pink, A200, A100, A400);
$bmw-fed-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$bmw-fed-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
//$bmw-fed-warn: mat-palette($mat-red);
$bmw-fed-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
//$bmw-fed-theme: mat-light-theme($bmw-fed-primary, $bmw-fed-accent, $bmw-fed-warn);

$bmw-fed-theme: mat.m2-define-light-theme((
  color: (
    primary: $bmw-fed-primary,
    accent: $bmw-fed-accent,
    warn: $bmw-fed-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($bmw-fed-theme);

@include mat.all-component-themes($bmw-fed-theme);

@import "./assets/scss/core/zd-custom.min.scss";

/* You can add global styles to this file, and also import other style files */
*:focus {
  outline: 0 !important;
  } 

@font-face {
  font-family: 'BMWRegular';
  font-style: normal;
  src:local('BMWRegular'),
      url('/assets/fonts/BMWTypeNextTT-Regular.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'BMWBold';
  src: url('/assets/fonts/BMWTypeNextTT-Bold.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'BMWLight';
  src: url('/assets/fonts/BMWTypeNextTT-Light.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'MINIBold';
  src: url('/assets/fonts/MINISansSerif-Bold.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'MINIRegular';
  src: url('/assets/fonts/MINISansSerif-Regular.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'MINISerifBold';
  src: url('/assets/fonts/MINISerif-Bold.woff') format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'MINISerifRegular';
  src: url('/assets/fonts/MINISerif-Regular.woff') format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'BMWMotorrad-Regular';
  src: url('/assets/fonts/BMWMotorrad-Regular.woff') format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'BMWMotorrad-Light';
  src: url('/assets/fonts/BMWMotorrad-Light.woff') format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'BMWMotorrad-Bold';
  src: url('/assets/fonts/BMWMotorrad-Bold.woff') format("woff");
  font-display: swap;
}

html,
body {
  margin: 0;
  height: 100%;
}

input {  
  font-family: 'BMWRegular', 'BMWLight';
}
textarea{
  font-family: 'BMWRegular', 'BMWLight';
}

ul, ol, li{
  font-family: 'BMWRegular', 'BMWLight';
}

.grecaptcha-badge {
  opacity: 0;
  display: none;
  visibility: hidden;
}

textarea.mat-input-element {
  font-family: 'BMWRegular', 'BMWLight' !important;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #000 !important;
  font-size: 1.2rem !important;
}

.primary{
  width: 100%;
  min-height: 50px;
  color: #fff !important;
  background-color: #1C69D4 !important;
  font-size: 14px;
  font-family: 'BMWBold' !important;
  border-color: #1C69D4 !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  &:hover {
    background-color: #0653b6 !important;
  }
  &:disabled  {
    background-color: rgb(28, 105, 212, .3) !important;
  }
}

.secondary{
  width: 100%;
  min-height: 50px;
  color: #fff !important;
  background-color: #4d4d4d !important;
  font-size: 14px;
  font-family: 'BMWBold' !important;
  border-color: inherit !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  &:hover {
    background-color: #262626 !important;
  }
  &:disabled  {
    background-color: rgb(77, 77, 77, .3) !important;
  }
}

.one-column > div{
  width: 100%;
  padding: 1em;
}

.one-column{
  display: grid;
  grid-gap: 10px;
}

.two-column > div{
  width: 100%;
  padding: 1em;
}

.two-column{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.three-column > div{
  width: 100%;
}

.three-column{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 320px) 
 and (max-device-width: 480px)
 and (-webkit-min-device-pixel-ratio: 2) {
  .two-column > div{
    width: 100%;
  }  
  .two-column{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .three-column > div{
    width: 100%;
  }
  
  .three-column{
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
 }
html, body { height: 100%; }
body { margin: 0; font-family: 'BMWRegular', 'BMWLight'; }
